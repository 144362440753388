import { Box, Grid, Typography } from "@mui/material";

const DailyRoutine = (routine) => {
    return (
        <Box
            sx={{
                bgcolor: '#e1f5fe',
                border: 1,
                width: '60%',
                marginX: '20%',
                marginY: '15px',
                alignContent: 'center'
            }}
        >
            <Typography variant="h2"
                sx={{
                    borderBottom: 1,
                    paddingY: '20px'
                }}
            >
                {routine.routine.day} - {routine.routine.theme}
            </Typography>
                {
                    routine.routine.exercises.map(exercise => 
                        <Grid container
                            direction='row'
                            justifyContent="center"
                            alignItems="center"
                            id={exercise.name}
                            alignContent='center'
                            paddingY='10px'
                            sx={{
                                borderBottom: 1,
                                borderTop: 1
                            }}
                        >
                            <Grid item xs={6} alignContent='right'>
                                <Typography variant="h2">{exercise.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                { assureContent(exercise.weight) &&
                                    <Typography>Weight: {exercise.weight}</Typography>
                                }

                                { assureContent(exercise.reps) &&
                                    <Typography>{formatRepsString(exercise.reps)}</Typography>
                                }

                                { assureContent(exercise.sets) &&
                                    <Typography><Typography>Sets: {exercise.sets}</Typography></Typography>
                                }
                            </Grid>
                        </Grid>
                    )
                }
        </Box>
    )
};

const assureContent = (input) => input && input.toString().toUpperCase() !== 'N/A';

const formatRepsString = (repsString) => {
    return repsString.toString().match(/(second|minute|hour|min)/) ?
    `Time: ${repsString}` :
    `Reps: ${repsString}`;
}

export default DailyRoutine;
