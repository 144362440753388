import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export const Header = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav" position='sticky'>
                <Toolbar>
                   <Typography
                        variant="h1"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Fit Coach AI - Your Personal Fitness Coach
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
