import { createTheme } from "@mui/material";

export const theme = createTheme();

theme.typography.h1 = {
    fontSize: '1.7rem'
}

theme.typography.h2 = {
    fontSize: '1.2rem'
}
