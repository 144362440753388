import React from 'react';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { useState } from "react";
import { postWorkoutQuery } from '../service/fit-ai-api';
import DailyRoutine from './daily-routine';

export const WorkoutForm = () => {
    const FormState = {
        READY: 'ready',
        LOADING: 'loading',
        COMPLETE: 'complete'
    }

    const [age, setAge] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [goal, setGoal] = useState('');
    const [gender, setGender] = useState('');
    const [fitnessLevel, setFitnessLevel] = useState('');
    const [formState, setFormState] = useState(FormState.READY);
    const [routine, setRoutine] = useState([]);
    
    const handleAgeChange = (event) => {
        setAge(event.target.value);
    }

    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    }

    const handleGoalChange = (event) => {
        setGoal(event.target.value);
    }

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    }

    const handleHeightChange = (event) => {
        setHeight(event.target.value);
    }

    const handleFitnessLevelChange = (event) => {
        setFitnessLevel(event.target.value);
    }

    const handleSubmit = () => {
        setFormState(FormState.LOADING);
        postWorkoutQuery(age, weight, goal, gender, height, fitnessLevel).then(workoutRoutine => {
            setRoutine(workoutRoutine);
            setFormState(FormState.COMPLETE);
        })
    };

    const handleReset = () => {
        setAge('');
        setWeight('');
        setGender('');
        setHeight('');
        setFitnessLevel('');
        setGoal('');
        setFormState(FormState.READY);
    }

    const renderSwitch = (state) => {
        switch(state) {
            case FormState.READY: 
                return <Grid container 
                    // pt={2} 
                    mx='20%'
                    spacing={2} 
                    width='60%'
                >
                    <Grid item xs={12}>
                        <Typography variant='h2' sx={{py:2}}>
                            Tell us a bit about yourself so that we can generate the perfect workout routine!
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{my:2}}>
                            <TextField
                                id='age-inpit'
                                label='Age'
                                value={age}
                                onChange={handleAgeChange}
                            />
                        </FormControl>
                    </Grid>
    
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{my:2}}>
                            <TextField
                                id='gender-input'
                                label='Gender'
                                value={gender}
                                onChange={handleGenderChange}
                            />
                        </FormControl>
                    </Grid>
    
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{my:2}}>
                            <TextField
                                id='height-inpit'
                                label='Height'
                                value={height}
                                onChange={handleHeightChange}
                            />
                        </FormControl>
                    </Grid>
    
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{my:2}}>
                            <TextField
                                id='weight-inpit'
                                label='Weight'
                                value={weight}
                                onChange={handleWeightChange}
                            />
                        </FormControl>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{my:2}}>
                            <InputLabel id="goal-select-label">Goal</InputLabel>
                            <Select
                                labelId="goal-select-label"
                                id="goal-select"
                                value={goal}
                                label="Goal"
                                onChange={handleGoalChange}
                                style={{minWidth: 120}}
                            >
                                <MenuItem value={'LOSE_WEIGHT'}>Lose Weight</MenuItem>
                                <MenuItem value={'BUILD_MUSCLE'}>Build Muscle</MenuItem>
                                <MenuItem value={'MAINTAIN'}>Maintain My Current Weight</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
    
                    <Grid item xs={6}>
                        <FormControl fullWidth sx={{my:2}}>
                            <InputLabel id="fitness-select-label">Fitness Level</InputLabel>
                            <Select
                                labelId="fitness-select-label"
                                id="fitness-select"
                                value={fitnessLevel}
                                label="Fitness Level"
                                onChange={handleFitnessLevelChange}
                                style={{minWidth: 120}}
                            >
                                <MenuItem value={'BEGINNER'}>Beginner</MenuItem>
                                <MenuItem value={'INTERMEDIATE'}>Intemediate</MenuItem>
                                <MenuItem value={'ADVANCED'}>Advanced</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
    
                    <FormControl fullWidth sx={{mt:2, marginLeft: '30%', marginRight: '30%', width: '40%'}}>
                        <Button
                            variant='contained'
                            disabled={formState === FormState.LOADING}
                            onClick={handleSubmit}
                        >
                            <Typography py={1} >Generate Workout Plan</Typography>
                        </Button>
                    </FormControl>
                </Grid>;
            
            case FormState.LOADING:
                return (
                    <Box>
                        <CircularProgress />
                        <Typography variant='h1'>Sorry this still takes a bit 😬</Typography>
                    </Box>
                );
            
            case FormState.COMPLETE:
                return (
                    <Box>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant='h1' align='center'>Your Plan</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant='contained'
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                        <Box>
                            { routine.map(day => <DailyRoutine routine={day}/> )}
                        </Box>
                    </Box>
                );
            
                default:
                    return (
                        <Typography variant='h1'>Uh oh! This shouldn't Happen!</Typography>
                    );
        }
    }

    return (
        <React.Fragment>
            <Box my={3}>
                { renderSwitch(formState) }
            </Box>
        </React.Fragment>
    );
}
