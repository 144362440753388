import './App.css';
import { WorkoutForm } from './component/workout-form';
import { Header } from './component/header';
import { ThemeProvider } from '@emotion/react';
import { theme } from './util/theme';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <title>Fit Coach AI - Your Personal Fitness Coach</title>
        <Header />
        <WorkoutForm />
      </ThemeProvider>
    </div>
  );
}

export default App;
