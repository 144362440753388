export const postWorkoutQuery = async (age, weight, goal, gender, height, fitnessLevel) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            age: age,
            weight: weight,
            goal: goal,
            gender: gender,
            height: height,
            fitnessLevel: fitnessLevel
        })
    };

    const response = await fetch(
        'https://api.fit-coach.ai/api/workouts',
        requestOptions
    )
    const workoutBody = await response.json();
    console.log(`Workout response: ${JSON.stringify(workoutBody)}`)

    return workoutBody;
}
